import ButtonPrimary from "shared/Button/ButtonPrimary";
import React from "react";
import { Helmet } from "react-helmet";
import NcImage from "shared/NcImage/NcImage";
import I404Png from "images/404.png";
import { getAuth, signOut } from "firebase/auth";
 
//const Logout: React.FC = () => (
  const Logout = (ref:any) => {
    const auth = getAuth();
    signOut(auth).then(() => {
     console.log("logout");
    }).catch((error) => {
      // An error happened.
    });
    return (
  <div className="nc-Page404">
    <Helmet>
      <title>404 || Moz Gold</title>
    </Helmet>
    <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-5">
      {/* HEADER */}
      <header className="text-center max-w-2xl mx-auto space-y-2">
         
        <span className="block text-sm text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-medium">
          Log out sucessfully, Thanks.{" "}
        </span>
   
        <div className="pt-8">
        <ButtonPrimary href="/shop-login">Login shop again</ButtonPrimary>
        </div>

      </header>
    </div>
  </div>
    )
}

export default Logout;
