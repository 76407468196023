import React, { FC, useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import SectionSliderCollections from "components/SectionSliderLargeProduct";
import SectionPromo1 from "components/SectionPromo1";
import ProductCard from "components/ProductCard";
import { PRODUCTS } from "data/data";
import SidebarFilters from "./SidebarFilters";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "utils/firebase";
import { useHistory, useLocation, useParams } from "react-router-dom";
import AuthContext from "context/AuthContext";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { ProgressbBarContext } from "context/ProgressBarContext";

export interface PageCollection2Props {
  className?: string;
  category?: string;
}

interface UrlParam{
  category: string
}
const PageCollection2: FC<PageCollection2Props> = ({ className = "" , category = ""}) => {
  const [data, setData] = useState([]);
  const [subCategoryInput,setSubCatory] = useState([]);
  const { state,dispatch } = useContext(ProgressbBarContext);

   const { user } = useContext(AuthContext);
  const history = useHistory();

  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/auth.user
      const uid = user.uid;
      // ...
    } else {
      history.push("/shop-login");
    }
  });


  //let location = useLocation();
  
  let urlParam: UrlParam= useParams();
  
  //urlParam.category 
 
  

  useEffect(() => {
    dispatch(true);
    const unsub = onSnapshot(
      collection(db, "products"),
      (snapShot) => {
        let list : any = [];
        snapShot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        list = list.filter((listItem: any) => listItem.category == urlParam.category)
        setData(list);
        dispatch(false);
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();
    };
  }, []);
  
  useEffect(() => {
    // action on update of movies
    console.log(subCategoryInput);
    if(subCategoryInput.length > 0){
      const unsub = onSnapshot(
        collection(db, "products"),
        (snapShot) => {
          let list : any = [];
          snapShot.docs.forEach((doc) => {
            list.push({ id: doc.id, ...doc.data() });
          });
          list = list.filter((listItem: any) => listItem.category == urlParam.category && listItem.subcategory == subCategoryInput[0])
          setData(list);
        },
        (error) => {
          console.log(error);
        }
      );
  
      return () => {
        unsub();
      };
    }
    else{
      const unsub = onSnapshot(
        collection(db, "products"),
        (snapShot) => {
          let list : any = [];
          snapShot.docs.forEach((doc) => {
            list.push({ id: doc.id, ...doc.data() });
          });
          list = list.filter((listItem: any) => listItem.category == urlParam.category)
          setData(list);
        },
        (error) => {
          console.log(error);
        }
      );
  
      return () => {
        unsub();
      };
    }
    
    //setData({ subCategoryInput, [...subCategoryInput, subCategoryInput], });

 }, [subCategoryInput]);

  const GetSubCategory = (subCategoryItem : string,checked : boolean) => {
    let list : any = [];
    if(checked){
      list.push(subCategoryItem);
    }
    setSubCatory(list);
    
 

    let filteredList = data.filter(item =>
      subCategoryInput.includes(item))
    // let list : any = [];
    // list = data;
    // if(checked){
    //   list = list.filter((listItem: any) => listItem.subcategory == subCategory)
    // }
    // else{
    //   list = list.filter((listItem: any) => listItem.subcategory != subCategory)
    // }
    

    //setData(list);
    //console.log(data);
   
   // let selectedCategoryList : any = [];
    //useState({ activeFilter: [...selectedCategoryList, subCategory] });
    

  };

  return (
    <div
      className={`nc-PageCollection2 ${className}`}
      data-nc-id="PageCollection2"
    >
      <Helmet>
        <title>Category || Moz Gold</title>
      </Helmet>

      <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 sm:space-y-20 lg:space-y-28">
        <div className="space-y-2 lg:space-y-14">
          {/* HEADING */}
          <div className="max-w-screen-sm">
            <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold">
              {urlParam.category}
            </h2>
            <span className="block mt-4 text-neutral-500 dark:text-neutral-400 text-sm sm:text-base">
              We not only help you design exceptional products, but also make it
              easy for you to share your designs with more like-minded people.
            </span>
          </div>

          <hr className="border-slate-200 dark:border-slate-700" />
          <main>
            {/* LOOP ITEMS */}
            <div className="flex flex-col lg:flex-col">
              <div className=" mt-2 lg:w-1/3 xl:w-1/4 pr-4">
                <SidebarFilters GetSubCategory={GetSubCategory} selectedCategory={urlParam.category}/>
              </div>
              <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mx-4 border-t lg:border-t-0"></div>
              <div className="flex-1 ">
                <div className="flex-1 grid grid-cols-2  sm:grid-cols-8 xl:grid-cols-4 md:grid-cols-4  gap-x-8  ">
                  {data.map((item, index) => (
                    <ProductCard data={item} key={index} />
                  ))}
                </div>
              </div>
            </div>
          </main>
        </div>

        {/* === SECTION 5 === */}
        <hr className="border-slate-200 dark:border-slate-700" />

        {/* <SectionSliderCollections /> */}
        <hr className="border-slate-200 dark:border-slate-700" />

        {/* SUBCRIBES */}
        {/* <SectionPromo1 /> */}
      </div>
    </div>
  );
};

export default PageCollection2;
