import React, { useContext, useEffect, useState } from "react";
import "./login.scss";
import { getAuth,onAuthStateChanged,signInAnonymously } from "firebase/auth";
import { auth, db, storage } from "../../utils/firebase.js";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link } from "react-router-dom";

import ButtonPrimary from "shared/Button/ButtonPrimary";
import ShopContext from "context/ShopContext";
import AuthContext from "context/AuthContext";
import { addDoc, collection, doc, getDocs, onSnapshot, query, serverTimestamp, setDoc, updateDoc, where } from "firebase/firestore";
import { ProgressbBarContext } from "context/ProgressBarContext";
 

const ShopLogin = () => {
  
  useState(false);
  const [data, setData] = useState<any>([]);
  const [error, seterror] = useState("");
  const [shopIdInput, setShopIdInput] = useState("");
  const [password, setPassword] = useState("");
  let { shopId,setShopId } = useContext(ShopContext);
  const { user } = useContext(AuthContext);
  const { state,dispatch } = useContext(ProgressbBarContext);




  const history = useHistory();

  const handleAdd = async () => {
    try {
      
      onAuthStateChanged(auth, async (user:any) => {
        
     
      data.shopId = shopIdInput;
      data.uid = user.uid;

      const q = query(collection(db, "users"), where("shopid", "==", shopIdInput));

      const querySnapshot = await getDocs(q);
      let docID = '';
      await querySnapshot.forEach((doc) => {
      // if email is you primary key then only document will be fetched so it is safe to continue, this line will get the documentID of user so that we can update it
        docID = doc.id;
      });
      const shop_user = await doc(db, "users", docID);

      // Set the "capital" field of the city 'DC'
      await updateDoc(shop_user, {
          uid : user.uid
      });
      dispatch(false);
      
      });
      //navigate(-1)
    } catch (err) {
      console.log(err);
    }
 
  };

  const handleSubmit = async (e:any) => {
    e.preventDefault();
    setShopIdInput("");
    setPassword("");
    //const res = await signInShop(email, password);
    dispatch(true);
    const unsub = onSnapshot(
      collection(db, "shop_information"),
      async (snapShot) => {
        var list :any[] = [] 
        snapShot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        list = list.filter((listItem) => listItem.shopid == shopIdInput)
        console.log("shoid checked");
        setData(list);
        if(list.length === 0){
            alert("Invalid Shop Id");
            dispatch(false);
          } 
          else{
            const auth = getAuth();
            signInAnonymously(auth)
              .then(() => {
                // Signed in..
                console.log("anonimiussss");
                setShopId(shopIdInput);
                handleAdd();
               
                history.push("/")
              })
              .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                // ...
              });
            
          }
        
         
      },
      (error) => {
        console.log(error);
      }
    );
   
  };

  return (
    <div className={`nc-PageLogin ]`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          SHOP LOGIN
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          {/* <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div> */}
          {/* OR */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              NEED TO VERIFY YOUR SHOP
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          {/* FORM */}
          <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-6" action="#" method="post">
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Shop Id
              </span>
              <Input
                type="text"
                placeholder="myshopid987"
                className="mt-1'"
                onChange={(e) => setShopIdInput(e.target.value)}
              />
            </label>
       
            <ButtonPrimary  type="submit">Sign In</ButtonPrimary>
          </form>

          {/* ==== */}
          {/* <span className="block text-center text-neutral-700 dark:text-neutral-300">
            New user? {` `}
            <Link className="text-green-600" to="/signup">
              Create an account
            </Link>
          </span> */}
        </div>
      </div>
    </div>
  );
};

export default ShopLogin;
