export const userColumns = [
  { field: "id", headerName: "ID", width: 70 },
  {
    field: "user",
    headerName: "User",
    width: 230,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={params.row.img} alt="avatar" />
          {params.row.username}
        </div>
      );
    },
  },
  {
    field: "email",
    headerName: "Email",
    width: 230,
  },

  {
    field: "address",
    headerName: "Address",
    width: 100,
  },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status}
        </div>
      );
    },
  },
];

export const productColumns = [
  // { field: "id", headerName: "ID", width: 70 },
  { field: "productId", headerName: "Product Id", width: 110 },
  {
    field: "title",
    headerName: "Product Title",
    width: 230,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={params.row.image} alt="avatar" />
          {params.row.title}
        </div>
      );
    },
  },

  // {
  //   field: "description",
  //   headerName: "Description",
  //   width: 100,
  // },
  {
    field: "category",
    headerName: "Category",
    width: 100,
  },
  {
    field: "instock",
    headerName: "Stock Status",
    width: 150,
  },
  // {
  //   field: "price",
  //   headerName: "Price",
  //   width: 100,
  // // },
  // {
  //   field: "subcategory",
  //   headerName: "Sub Category",
  //   width: 150,
  // },
  // {
  //   field: "material",
  //   headerName: "Material",
  //   width: 100,
  // },
  // {
  //   field: "weight",
  //   headerName: "Weight",
  //   width: 100,
  // },
];

export const orderColumns = [
   { field: "orderId", headerName: "Order Id", width: 110 },
  { field: "productId", headerName: "Product Id", width: 110 },
  {
    field: "shopId",
    headerName: "Shop Id",
    width: 100,
  },
  {
    field: "title",
    headerName: "Product Title",
    width: 130,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={params.row.image} alt="avatar" />
          {params.row.title}
        </div>
      );
    },
  },
  {
    field: "timeStamp",
    headerName: "Order Date",
    width: 100,
  },
  {
    field: "delivery_date",
    headerName: "Delivery Date",
    width: 100,
  },
  
  {
    field: "order_status",
    headerName: "Order Status",
    width: 130,
  },
  {
    field: "customization_details",
    headerName: "Details",
    width: 130,
  },
  {
    field: "phone_number",
    headerName: "Mobile",
    width: 130,
  },
  
  

];

