import ButtonPrimary from "shared/Button/ButtonPrimary";
import React from "react";
import { Helmet } from "react-helmet";
import NcImage from "shared/NcImage/NcImage";
import I404Png from "images/404.png";
import { getAuth, signOut } from "firebase/auth";
import Alert from '@mui/material/Alert';
import { Box, Button, Collapse, IconButton } from "@mui/material";

//const Logout: React.FC = () => (
  const OrderStatus = (ref:any) => {
    const auth = getAuth();
    const [open, setOpen] = React.useState(true);
   
   
    return (
  <div className="nc-Page404">
    <Helmet>
      <title>Order status|| Moz Gold</title>
    </Helmet>
    <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-5">
      {/* HEADER */}
      <header className="text-center max-w-2xl mx-auto space-y-2">
         
        <span className="block text-sm text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-medium">
        <Box sx={{ width: '100%' }}>
      <Collapse in={open}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              
            >
             </IconButton>
          }
          sx={{ mb: 2 }}
        >
          Order placed successfully!!! Goto order to check status.
        </Alert>
      </Collapse>
 
    </Box>
        </span>
   
        <div className="pt-8">
        <ButtonPrimary href="/account-my-order">Goto order</ButtonPrimary>
        </div>

      </header>
    </div>
  </div>
    )
}

export default OrderStatus;
