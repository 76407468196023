import { getAuth, signOut,onAuthStateChanged } from "firebase/auth";
import { useState, useEffect } from 'react';
import AuthContext from './AuthContext'
 
const auth = getAuth()
export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            setUser(user);
            let userSessionTimeout = null;

            if (user === null && userSessionTimeout) {
              clearTimeout(userSessionTimeout);
              userSessionTimeout = null;
            } else {
              user.getIdTokenResult().then((idTokenResult) => {
                const authTime = idTokenResult.claims.auth_time * 1000;
                const sessionDurationInMilliseconds = 60 * 60 * 1000; // 60 min
                const expirationInMilliseconds = sessionDurationInMilliseconds - (Date.now() - authTime);
                userSessionTimeout = setTimeout(() =>     signOut(auth).then(() => {
                    console.log("logout");
                   }).catch((error) => {
                     // An error happened.
                   }), expirationInMilliseconds);
              });
            }
        })
    }, []);

    return (
        <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>
    );
};