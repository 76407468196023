const ProgressBarReducer = (state, action) => {
 if(action){
  return  true;
 }
 else{
  return  false;
 }
  
 
      
  
};

export default ProgressBarReducer;
