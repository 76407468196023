import { createContext, useReducer } from "react";
import ProgressBarReducer from "./progressBarReducer";


export const ProgressbBarContext = createContext();

export const ProgressBarContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ProgressBarReducer,false);

  return (
    <ProgressbBarContext.Provider value={{ state: state, dispatch }}>
      {children}
    </ProgressbBarContext.Provider>
  );
};
