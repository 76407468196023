import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useState, useEffect } from 'react';
import ShopContext from './ShopContext'
import { collection, onSnapshot } from "firebase/firestore";
import {  db } from "../utils/firebase.js";

const auth = getAuth()
export const ShopProvider = ({ children }) => {
    const [shopId, setShopId] = useState(null);
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            setShopId(shopId);
            if(!shopId && user && user.uid){
                const unsub = onSnapshot(
                    collection(db, "users"),
                    async (snapShot) => {
                      let list  = [];
                      snapShot.docs.forEach((doc) => {
                        list.push({ id: doc.id, ...doc.data() });
                      });
                      list = list.filter((listItem) => listItem.uid == user.uid);
                      if(list.length > 0){
                        console.log("uid found ");   
                        setShopId(list[0].shopid);
                      }                                   
                    },
                    (error) => {
                      console.log(error);
                    }
                  );
            }
           
        })
    }, []);

    return (
        <ShopContext.Provider value={{ shopId,setShopId }}>{children}</ShopContext.Provider>
    );
};