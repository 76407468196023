import React, { FC, useEffect, useRef, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import NcModal from "shared/NcModal/NcModal";

export interface ModalEditProps {
  show: boolean;
  onCloseModalEdit: () => void;
  onModalSubmit: () => void;
  onStatusSelected: (selectedStatus: string) => void;
}

const ModalEdit: FC<ModalEditProps> = ({ show,onCloseModalEdit,onModalSubmit,onStatusSelected}) => {
  const textareaRef = useRef(null);
  const [statusSelected, setStatusSelected] = useState('');

  
  const handleStockStatusInput = (e:any) => {
     const value = e.target.value;
    //onStatusSelected = value;
    onStatusSelected(value)
 
    //setData({ ...data, [id]: value });

  };

  const handleStockStatusSubmit = () => {

    onModalSubmit();

    //setData({ ...data, [id]: value });

  };

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        const element: HTMLTextAreaElement | null = textareaRef.current;
        if (element) {
          (element as HTMLTextAreaElement).focus();
          (element as HTMLTextAreaElement).setSelectionRange(
            (element as HTMLTextAreaElement).value.length,
            (element as HTMLTextAreaElement).value.length
          );
        }
      }, 400);
    }
  }, [show]);



  const renderContent = () => {
    return (
      <form action="#">
        <h3 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
          Change Product Stock
        
        </h3>
        <span className="text-sm">Select the current stock status:</span>
        <div className="mt-8 relative rounded-md shadow-sm">
        <select onChange={handleStockStatusInput}
              id="currency"
              name="currency"
              className="focus:ring-indigo-500 focus:border-indigo-500 h-full   border-transparent bg-transparent text-neutral-500 dark:text-neutral-300 sm:text-sm rounded-md"
            >
              <option value="na">Select</option>
              <option value="Ready to Make">Ready to Make</option>
              <option value="Out of stock">Out of stock</option>
              <option value="In Stock">In Stock</option>
            </select>
 
        </div>
        <div className="mt-4 space-x-3">
          <ButtonPrimary type="button"  onClick={onModalSubmit}>Submit</ButtonPrimary>
          <ButtonSecondary type="button" onClick={onCloseModalEdit}>
            Cancel
          </ButtonSecondary>
        </div>
      </form>
    );
  };

  const renderTrigger = () => {
    return null;
  };

 

  return (
    <NcModal
      isOpenProp={show}
      onCloseModal={onCloseModalEdit}
      contentExtraClass="max-w-lg"
      renderContent={renderContent}
      renderTrigger={renderTrigger}
      modalTitle=""
    />
  );
};

export default ModalEdit;
