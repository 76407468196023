import React, { useContext, useRef } from "react";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionPromo1 from "components/SectionPromo1";
import { Helmet } from "react-helmet";
import SectionHero2 from "components/SectionHero/SectionHero2";
import SectionSliderLargeProduct from "components/SectionSliderLargeProduct";
import SectionSliderProductCard from "components/SectionSliderProductCard";
import DiscoverMoreSlider from "components/DiscoverMoreSlider";
import SectionGridMoreExplore from "components/SectionGridMoreExplore/SectionGridMoreExplore";
import SectionPromo2 from "components/SectionPromo2";
import SectionSliderCategories from "components/SectionSliderCategories/SectionSliderCategories";
import SectionGridFeatureItems from "./SectionGridFeatureItems";
import SectionPromo3 from "components/SectionPromo3";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionMagazine5 from "containers/BlogPage/SectionMagazine5";
import Heading from "components/Heading/Heading";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { PRODUCTS, SPORT_PRODUCTS } from "data/data";
import SectionSliderCollections from "components/SectionSliderLargeProduct";
import ProductCard from "components/ProductCard";
import { useHistory } from "react-router-dom";
import AuthContext from "context/AuthContext";
import { getAuth, onAuthStateChanged } from "firebase/auth";

 

function PageHome() {
   const { user } = useContext(AuthContext);
  const history = useHistory();

  const auth = getAuth();
onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/auth.user
    const uid = user.uid;
    // ...
  } else {
    history.push("/shop-login");
  }
});
  
  const scollToRef = useRef<HTMLDivElement>(null);
  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>Moz Gold || Jewels</title>
      </Helmet>

      {/* SECTION HERO */}
      <SectionHero2 />

      {/* <div className="mt-24 lg:mt-32">
        <DiscoverMoreSlider />
      </div> */}

      <div className="container relative space-y-12 lg:space-y-32 lg:my-32">
        {/* SECTION */}
       
        

        {/* <div className="py-24 lg:py-32 border-t border-b border-slate-200 dark:border-slate-700">
          <SectionHowItWork />
        </div> */}

        {/* SECTION */}
        {/* <SectionPromo1 /> */}

        {/* SECTION */}
        <div className="relative py-6 lg:py-32" id="explore">
          <BackgroundSection />
          <SectionGridMoreExplore />
        </div>
        {/* <SectionSliderProductCard
          heading="Best Sellers"
          subHeading="Best selling of the month"
        /> */}

        {/*  */}
        <SectionPromo2/>

        {/* SECTION 3 */}
        {/* <SectionSliderLargeProduct cardStyle="style2" /> */}

        {/*  */}
        {/* <SectionSliderCategories /> */}

        {/* SECTION */}
        {/* <SectionPromo3 /> */}

        {/* SECTION */}
        {/* <SectionGridFeatureItems /> */}

   

        {/*  */}
      
      </div>
    </div>
  );
}

export default PageHome;
