import React, { FC, useContext } from "react";
import MainNav2Logged from "./MainNav2Logged";
import { Box, LinearProgress } from "@mui/material";
import { ProgressbBarContext } from "context/ProgressBarContext";
 
export interface HeaderLoggedProps {}

const HeaderLogged: FC<HeaderLoggedProps> = () => {
  const { state,dispatch } = useContext(ProgressbBarContext);
  
  const getProgressBar = () => {
    if(state){
      return (
  
        <Box sx={{ width: '100%' }}>
        <LinearProgress color="warning" />
      </Box>
      )
    }
   
  }
  return (
    <div className="nc-HeaderLogged sticky top-0 w-full z-40 ">

      <MainNav2Logged />
      {getProgressBar()}
    </div>
   
  );
};

export default HeaderLogged;
